<template>
  <v-menu
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        :disabled="invalid"
        small
      >
        ...
      </v-btn>
    </template>
    <v-card>
      <v-list v-if="controls !== false">
        <v-list-item
          v-for="(control,index) in controls"
          :key=index
        >
          <v-list-item-title>
            <v-btn dark :color="control.color" @click="control.do(item)">{{ control.text }}</v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
      invalid(){
        if(this.controls === false || this.controls.length === 0) return true
        return false
      }
  },
  props: {
    controls: {
      type: [Array,Boolean],
      default: false,
      required: false,
    },
    item: Object
  },

}
</script>

<style>

</style>